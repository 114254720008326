<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>
                {{
                  $route.name == route.form ? "Create New User" : "Update User"
                }}
              </strong>
              <div v-if="$route.name != route.form && form.email != user.email"
                   class="card-header-actions">
                <b-button size="sm"
                          variant="danger"
                          v-b-tooltip.hover
                          title="Delete"
                          @click="handleDelete">
                  <i class="icon-trash"></i>
                </b-button>
              </div>
            </div>
            <b-row>
              <BuildingDropdown :server-params="form" :form-group="true"/>
              <b-col sm="12">
                <b-form-group>
                  <label for="role">Role</label>
                  <treeselect id="role"
                              :options="options.role"
                              v-model="form.role"
                              placeholder="Select Role"
                              :clearable="false" />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <form-group :validator="$v.form.fullName"
                            label="Full Name"
                            :use-horizontal="false">
                  <b-form-input type="text"
                                id="fullName"
                                placeholder="Enter your full name"
                                autocomplete="off"
                                v-model="form.fullName"></b-form-input>
                </form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <form-group :validator="$v.form.email"
                            label="Email"
                            :use-horizontal="false">
                  <b-form-input type="email"
                                id="email"
                                placeholder="Enter your email"
                                autocomplete="off"
                                v-model="form.email"
                                :disabled="$route.name != route.form"></b-form-input>
                </form-group>
              </b-col>
            </b-row>
            <b-row v-if="$route.name == 'UserManagementUpdate'">
              <b-col sm="12">
                <b-form-group>
                  <label for="status">Status</label>
                  <treeselect id="status"
                              v-model="form.actived"
                              :options="options.status"
                              placeholder="Select Status"
                              :clearable="false"
                              :disabled="form.email == user.email" />
                </b-form-group>
              </b-col>
            </b-row>
            <template>
              <h6 style="margin-top: 1.25rem">
                {{
                  $route.name != route.form ? "Change Password" : "Set Password"
                }}
                <br />
                <small v-if="$route.name != route.form">
                  Leave it blank if the password will not be changed
                </small>
              </h6>
              <b-row>
                <b-col sm="12">
                  <form-group :validator="$v.form.newPassword"
                              label="New Password"
                              :use-horizontal="false">
                    <b-form-input type="password"
                                  placeholder="Enter new password"
                                  autocomplete="off"
                                  v-model="form.newPassword"></b-form-input>
                  </form-group>
                </b-col>
                <b-col sm="12">
                  <form-group :validator="$v.form.confirmNewPassword"
                              label="Confirm New Password"
                              :use-horizontal="false">
                    <b-form-input type="password"
                                  placeholder="Repeat new password"
                                  autocomplete="off"
                                  v-model="form.confirmNewPassword"></b-form-input>
                  </form-group>
                </b-col>
              </b-row>
            </template>
            <div slot="footer">
              <b-button @click="$router.go(-1)" size="sm">Back</b-button>
              <b-button type="submit"
                        size="sm"
                        variant="success"
                        class="float-right">
                {{ $route.name == route.name ? "Submit" : "Save Changes" }}
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { required, email, sameAs } from "vuelidate/lib/validators";
  import { role, status } from "@/shared/options";
  import BuildingDropdown from "@/components/common/BuildingDropdown";

  export default {
    data: () => ({
      route: {
        form: "UserManagementCreate",
        table: "UserManagement",
      },
      form: {
        fullName: null,
        email: null,
        actived: true,
        newPassword: null,
        role: null,
        confirmNewPassword: null,
        buildingId: null,
      },
      options: {
        status,
        buildings: [],
        role,
      },
    }),
    validations() {
      const self = this;

      if (
        self.$route.name == self.route.form ||
        (self.$route.name != self.route.form && self.hasPassword)
      ) {
        return {
          form: {
            fullName: { required },
            email: { required, email },
            newPassword: { required },
            buildingId: { required },
            confirmNewPassword: {
              required,
              sameAsNewPassword: sameAs("newPassword"),
            },
          },
        };
      } else {
        return {
          form: {
            fullName: { required },
            email: { required, email },
            buildingId: { required },
          },
        };
      }
    },
    components: {
      BuildingDropdown,
    },
    computed: {
      ...mapGetters("auth", ["user"]),
      hasPassword() {
        const self = this;
        return (
          !(self.form.newPassword === "" || self.form.newPassword === null) ||
          !(
            self.form.confirmNewPassword === "" ||
            self.form.confirmNewPassword === null
          )
        );
      },
    },
    created() {
      const self = this;
      if (self.$route.name != self.route.form) {
        self.get();
      }
      this.getBuildings();
    },
    methods: {
      get() {
        const self = this;

        let loader = self.$loading.show();
        self.$store
          .dispatch("apis/get", {
            url: `/user/${self.$route.params.id}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });

              self.$router.push({ name: self.route.table });
            } else {
              self.form = {
                fullName: response.data.fullName,
                email: response.data.username,
                role: response.data.role.id,
                actived: response.data.actived,
                buildingId: response.data.buildingId,
                newPassword: null,
                confirmNewPassword: null,
              };
            }
            loader.hide();
          });
      },
      getBuildings() {
        const self = this;
        self.$store
          .dispatch("apis/get", {
            url: "/buildings",
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.options.buildings = response.data.data.map((x) => ({
                id: x.id,
                label: x.name,
              }));
            }
          });
      },
      handleSubmit() {
        const self = this;

        self.$v.form.$touch();
        if (self.$v.form.$pending || self.$v.form.$error) return;

        let _confirmText = "",
          _okText = "",
          _action = "",
          _url = "";

        if (self.$route.name == self.route.form) {
          _confirmText = "You are about to submit this user. Are you sure ?";
          _okText = "Yes, Submit";
          _action = "apis/post";
          _url = "/user";
        } else {
          _confirmText = "You are about to update this user. Are you sure ?";
          _okText = "Yes, Update";
          _action = "apis/put";
          _url = `/user/${self.$route.params.id}`;
        }

        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: self.form,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  if (
                    self.user.id == self.$route.params.id &&
                    self.user.email == self.form.email
                  ) {
                    self.user.fullName = self.form.fullName;
                    self.user.actived = self.form.actived;
                    self.$store.dispatch("auth/updateUser", self.user);
                  }

                  self.$router.go(-1);
                }
              });
          });
      },
      handleDelete() {
        const self = this;

        self.$dialog
          .confirm("You are about to delete this user. Are you sure ?", {
            okText: "Yes, Delete",
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch("apis/remove", {
                url: `/user/${self.$route.params.id}`,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$router.go(-1);
                }
              });
          });
      },
    },
  };
</script>
